<template>
	<div class="container">
		<Header :flag="topFlag" />	
		<div class="banner">
			 <div class="banner-content">
				 <div class="banner-title">
					 <span class="banner-sub-title">在您的选择里<br/>隐藏起来的利润</span>
					 <br/>
					 <span class="banner-sub-title1">对您来说，实现互利共赢，双向选择，何乐而不为呢？您还在等什么？</span>
				 </div>
			 </div>
		</div>
		<div class="main">
			<div class="nav-title">
				<ul class="nav-ul">
					<li  @click="selectCse(1);">易拓客 <br><div :class="flag===1?'isAction':''"></div></li>
					<li  @click="selectCse(2);">电销外呼防封电话<br><div :class="flag===2?'isAction':''"></div></li>
					<li  @click="selectCse(3);">短信群发营销<br><div :class="flag===3?'isAction':''"></div></li>
					<li  @click="selectCse(4);">智能名片<br><div :class="flag===4?'isAction':''"></div></li>
					<li  @click="selectCse(5);">拓客活动<br><div :class="flag===5?'isAction':''"></div></li>
				</ul>
			</div>
			<div class="case">
				<div class="case-list">
					<div class="case-box" v-for="item in list">
						<div class="case-img" v-if="item.image!=null && item.image!=''">
							<img :src="item.image" />
						</div>
						<div class="case-content">
							<span class="case-title">{{item.title}}</span>
							<br>
							<span class="case-intro">{{item.intro}}</span>
						</div>
					</div>
				</div>
		 </div>
		 <div class="pagination-container">
		   <el-pagination
		 	background
		 	:hide-on-single-page="onlyOnePage"
		 	@current-change="handleCurrentChange"
		 	layout="prev, pager, next"
		 	:page-size="pageSize"
		 	:total="total">
		   </el-pagination>
		 </div>
		</div>	
		<Footer />	
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		metaInfo: {
			title: '蓝莓科技客户案例',
			meta: [
			  {
				name: 'keywords',
				content: '客户案例,蓝莓科技客户案例'
			  },
			  {
				name: 'description',
				content: '蓝莓科技客户案例频道，展现合作伙伴、产品成功案例等信息，截止目前已累计服务客户规模超过万家，并获得用户一致好评'
			  }
			]
		},
		name: 'CustomCase',
		components: {
			Header,
			Footer
		},	
		data(){
			return{
			   topFlag:'case',
			   flag: 0,
			   totalPage: 0,
			   pageSize: 12,
			   total: 0,
			   pageNum: 1,
			   onlyOnePage:false,
			   list:[],
			}
		},
		created() {
			this.getList();
		},
		methods:{
			selectCse(key){
				this.flag=key;
				this.pageNum = 1;
				this.$http.getCaseList({pageNum:this.pageNum,pageSize:this.pageSize,type:key}).then(res => {
					if(res.data!=null){
						this.list = res.data.list;
						this.totalPage=res.data.pages;
						this.total=res.data.total;
						if(res.data.pages<=1){
							this.onlyOnePage=true;
						}
					}
				});
			},
			handleCurrentChange(val) {
			  this.flag=this.flag;
			  this.pageNum = val;
			  this.getList();
			},
			getList(){
				this.$http.getCaseList({pageNum:this.pageNum,pageSize:this.pageSize,type:this.flag}).then(res => {
					if(res.data!=null){
						this.list = res.data.list;
						this.totalPage=res.data.pages;
						this.total=res.data.total;
						if(res.data.pages<=1){
							this.onlyOnePage=true;
						}
					}
				})
			},
		}
	}	
</script>

<style scoped lang="less">
	.banner{
		 padding-top: 80px;
		 height: 450px;
		 position: relative;
		 background-image: url(https://file.lanmeihulian.com/in_2.jpg);
		 background-repeat: no-repeat;
		 background-size: 100% auto;
		 background-position: 50%;
		 .banner-content{
			 display: flex;
			 display: -webkit-flex;
			 flex-direction:row;
			 width: 1200px;
			 margin: auto;
			 .banner-title{
				 text-align: left;
				 .banner-sub-title{
					 display: inline-block;
					 margin-top: 80px;
					 font-size: 48px;
					 line-height: 80px;
					 font-weight: 600;
					 color: white;
				 }
				 
				 .banner-sub-title1{
					width: 490px;
					display: inline-block;
					margin-top: 20px;
					font-size: 20px;
					line-height: 45px;
					color: white; 
				 }
				 
			 }
			 
		 }
	}	
	.main{
		width: 1200px;
		margin: auto;
		.nav-title{
			height: 90px;
			.nav-ul{
				margin-top: 30px;
				height: 60px;
				display: flex;
				display: -webkit-flex;
				li{
					margin: auto;
					height: 90px;
					line-height: 50px;
					font-size: 24px;
					text-align: center;
					padding: 0px 20px 0px 20px;
					.isAction{
						width: 100px;
						background-color: #409EFF;
						height: 6px;
						border-radius: 5px;
						margin: auto;
					}
				}
			}
		}	
		.case{
			.title{
				font-size: 32px;	
				font-weight: 600;
				text-align: center;
			}
			.case-list{
				display: flex;
				display: -webkit-flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				.case-box{
					width: 350px;
					height: auto;
					margin: 20px 20px 20px 20px;
					cursor: pointer;
					background: #fff;
					box-shadow: 0 4px 12px 0 #DCDCDC;
					border-radius: 4px;
					position: relative;
					-webkit-transition: -webkit-transform .2s;
					transition: transform .2s,-webkit-transform .2s,-moz-transform .2s;
					.case-img{
						img{
							width: 350px;
						}
					}
					.case-content{
						width: 320px;
						height: auto;
						margin: auto;
						.case-title{
							margin-top: 20px;
							display: inline-block;
							width: 320px;
							text-align: center;
							font-size: 24px;
							color: #333333;
							line-height: 40px;
							// white-space: nowrap;  /*强制span不换行*/
							// display: inline-block;  /*将span当做块级元素对待*/
							// overflow: hidden;  /*超出宽度部分隐藏*/
							// text-overflow: ellipsis;  /*超出部分以点号代替*/
						}
						.case-intro{
							display: inline-block;
							margin-bottom: 30px;
							width: 300px;
							text-align: center;
							font-size: 16px;
							color: #666666;
							line-height: 25px;
							padding-top: 20px;
							
						}
					}
				}
				.case-box:hover{
					 box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
						transform: translate(0,-5px);
						transition-delay: 0s !important;
				}	
			}
		}
		.pagination-container{
			margin-top: 40px;
			padding-top: 20px;
			text-align: right;
			width: 100%;
			height: 50px;
			margin-bottom: 40px;
		}
	}		
</style>
